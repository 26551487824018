// @flow
import React from 'react';
import type {Node} from 'react';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {
  Box,
  DialogTitle,
  DialogContent,
  Divider,
  MarkdownViewer,
  Typography,
  Stack,
  Grid,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useContactMethods} from '../../hooks';
import {useResourcesContext} from '../../contexts';
import {ContactMethod} from '../ContactMethod';
import {CollectionIcon} from '../CollectionIconList';

type PropsType = {
  resource: UIResourceType,
  onClose: () => void,
};

export const ResourceDetailView = ({resource, onClose}: PropsType): Node => {
  const {contactMethods} = useContactMethods(resource);
  const {
    setSearchTerm,
    collectionFilter,
    setCollectionFilter,
  } = useResourcesContext();

  const handleIconClick = (collectionIds: Array<string>) => {
    setCollectionFilter(collectionIds);
    onClose();
    setSearchTerm('');
  };

  return (
    <Box>
      <DialogTitle>
        <Typography sx={styles.title}>{resource.title}</Typography>
        {!!resource.subtitle && (
          <Typography sx={styles.subtitle}>{resource.subtitle}</Typography>
        )}
      </DialogTitle>
      <DialogContent>
        <Box mb={3}>
          <MarkdownViewer value={resource.description} />
        </Box>

        {!!resource.dateTimeInformation && (
          <Typography sx={styles.dateTimeText}>
            {resource.dateTimeInformation}
          </Typography>
        )}

        <Stack direction={{xs: 'column', sm: 'row'}} spacing={4}>
          <Stack sx={styles.contactMethods} spacing={2} divider={<Divider />}>
            {contactMethods.map(({iconName, text, href}, index) => (
              <ContactMethod
                key={index}
                iconName={iconName}
                text={text}
                href={href}
              />
            ))}
          </Stack>

          <Box sx={styles.collectionWrapper}>
            <Typography sx={styles.collectionTitle}>Categories</Typography>
            <Grid container spacing={2}>
              {resource.collections.map((collection) => (
                <Grid item key={collection.id}>
                  <Box sx={styles.collectionIconWrapper}>
                    <CollectionIcon
                      collection={collection}
                      selectedCollections={collectionFilter}
                      setSelectedCollections={handleIconClick}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </DialogContent>
    </Box>
  );
};

const styles = {
  title: {
    color: Theme.colorPalette.onSurface,
    fontSize: 22,
    lineHeight: '24px',
    marginBottom: 1,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: Theme.colorPalette.onSurface,
  },
  dateTimeText: {
    fontSize: 14,
    color: Theme.colorPalette.dark,
    marginBottom: 2,
  },
  contactMethods: {
    backgroundColor: Theme.colorPalette.secondary4,
    padding: 2,
    borderRadius: 3,
    maxWidth: {
      xs: '100%',
      sm: '70%',
    },
  },
  collectionWrapper: {
    maxWidth: {
      xs: '100%',
      sm: '30%',
    },
  },
  collectionTitle: {
    marginBottom: 1,
    fontWeight: 700,
    fontSize: 14,
  },
  collectionIconWrapper: {
    width: {
      xs: '80px',
      sm: '50px',
    },
  },
};
