// @flow
import React from 'react';
import type {ComponentType} from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconNames,
  Box,
  Popover,
  IconButton,
  Divider,
} from '@wellstone-solutions/web';

import useMediaQuery from '@mui/material/useMediaQuery';

import {useTheme} from '@mui/material/styles';

type PropsType = {
  children: React$Node,
  anchorEl: HTMLElement | null,
  onClose: () => void,
  title: string | '',
};

const POPOVER_WIDTH = '315px';
const MAX_DIALOG_WIDTH = '900px';

export const SiteHeaderDrawer: ComponentType<PropsType> = (props) => {
  const {children, onClose, title, anchorEl} = props;
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleClose = () => {
    onClose();
  };

  return isMobile ? (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      PaperProps={{sx: style.dialog_paper}}
      sx={style.dialog}
      data-testid="header-drawer-mobile">
      <DialogTitle sx={{padding: 0}}>
        <Box style={style.drawer_header}>
          <Box>{title}</Box>
          <Box>
            <IconButton
              onClick={handleClose}
              icon={IconNames.Xmark}
              iconProps={{name: IconNames.Xmark, style: {strokeWidth: 2}}}
              sx={{mr: 0.2}}
              data-testid="close-dialog"
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  ) : (
    <Popover
      data-testid="header-drawer-desktop"
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      sx={style.popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <Box style={style.drawer_header}>
        <Box>{title}</Box>
        <Box>
          <IconButton onClick={handleClose} icon={IconNames.Xmark}></IconButton>
        </Box>
      </Box>
      <Divider />
      <Box sx={style.drawer_content}>{children}</Box>
    </Popover>
  );
};

const style = {
  dialog: {
    position: 'fixed',
    top: '80px',
    maxWidth: '100vw',
    padding: 0,
    margin: 0,
  },
  dialog_paper: {
    width: '100vw',
    maxWidth: MAX_DIALOG_WIDTH,
    padding: 0,
    margin: 0,
  },
  popover: {
    '& .MuiPopover-paper': {
      borderRadius: '10px',
      width: POPOVER_WIDTH,
      papdding: 0,
    },
  },
  drawer_header: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '0.25px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
  },
  drawer_content: {
    padding: '10px',
  },
};
