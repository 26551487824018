// @flow
import React, {useState, useEffect} from 'react';
import {observer} from 'mobx-react';
import type {ComponentType, Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  Icon,
  IconNames,
} from '@wellstone-solutions/web';
// TODO replace with import from common libs when available
import {Collapse} from '@mui/material';
import {CollectionIcon} from './CollectionIcon';
import {useStores} from 'hooks/useStores';
import {Carousel} from 'components/Carousel';

type PropsType = {
  selectedCollections: Array<string>,
  setSelectedCollections: (Array<string>) => void,
};

export const CollectionIconList: ComponentType<PropsType> = observer(
  ({selectedCollections, setSelectedCollections}: PropsType): Node => {
    const {resourceStoreV2} = useStores();
    const [showIcons, setShowIcons] = useState(true);

    useEffect(() => {
      const getCollections = async () => {
        await resourceStoreV2.getCollections();
      };

      getCollections();
    }, [resourceStoreV2]);

    return (
      <Box>
        <Divider sx={styles.divider} />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon name={IconNames.ViewColumns2} />
            <Typography variant="body1" sx={styles.title}>
              Browse Categories
            </Typography>
          </Stack>
          <Button
            onClick={() => setShowIcons(!showIcons)}
            variant="text"
            sx={styles.headerButton}>
            {showIcons ? 'Hide' : 'View All'}
          </Button>
        </Stack>
        <Collapse in={showIcons}>
          <Box sx={styles.carouselWrapper}>
            <Carousel>
              {resourceStoreV2.collections.map((collection) => (
                <CollectionIcon
                  key={collection.id}
                  collection={collection}
                  selectedCollections={selectedCollections}
                  setSelectedCollections={setSelectedCollections}
                />
              ))}
            </Carousel>
          </Box>
        </Collapse>
        <Divider sx={styles.divider} />
      </Box>
    );
  },
);

const styles = {
  divider: {
    height: '1px',
    marginTop: 1,
    marginBottom: 1,
  },
  title: {
    fontSize: '14px',
    fontWeight: 700,
    color: Theme.colorPalette.onSurface,
  },
  headerButton: {
    textTransform: 'none',
    fontWeight: 700,
  },
  iconStack: {
    marginTop: 2,
    overflowX: 'scroll',
  },
  carouselWrapper: {
    marginTop: 1,
  },
  label: {
    fontSize: '11px',
    textAlign: 'center',
    color: Theme.colorPalette.onSurface,
    marginTop: 1,
  },
};
