// @flow
import React, {useEffect, useRef} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {Box} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {ResourceGrid} from '../ResourceGrid';
import {CollectionIconList} from '../CollectionIconList';
import {useResourceGridDataloader} from '../../dataloaders';
import {useResourcesContext} from '../../contexts';

export const AllResources: ComponentType<{}> = observer((): Node => {
  const {locationStore} = useStores();
  const initialLoad = useRef(true);
  const {
    searchTerm,
    collectionFilter,
    setCollectionFilter,
  } = useResourcesContext();

  const {
    isLoading,
    resources,
    totalCount,
    currentPage,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  } = useResourceGridDataloader();

  useEffect(() => {
    if (initialLoad.current === false) {
      onFilterChange();
    } else {
      initialLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, collectionFilter, locationStore.currentLocation]);

  return (
    <Box>
      <Box mb={3}>
        <CollectionIconList
          selectedCollections={collectionFilter}
          setSelectedCollections={setCollectionFilter}
        />
      </Box>
      <ResourceGrid
        isLoading={isLoading}
        resources={resources}
        totalCount={totalCount}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </Box>
  );
});
