// @flow
import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import type {UIResourceAnswerType} from '@wellstone-solutions/common';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {
  Box,
  Button,
  Dialog,
  Icon,
  IconButton,
  IconNames,
  Stack,
} from '@wellstone-solutions/web';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {hasFlag, RESOURCES_V2} from 'constants/FeatureFlags';
import {useStores} from 'hooks/useStores';
import {RESOURCE_MODAL_VIEWS} from '../../constants';
import {AnswerDetailView} from './AnswerDetailView';
import {ResourceDetailView} from './ResourceDetailView';
import {ShareView} from './ShareView';

type PropsType = {
  open: boolean,
  onClose: () => void,
  resource: null | UIResourceAnswerType | UIResourceType,
  defaultView?: string,
};

export const ResourceModal = ({
  open,
  onClose,
  resource,
  defaultView = RESOURCE_MODAL_VIEWS.detail,
}: PropsType): Node => {
  const {meStore} = useStores();
  // Remove RESOURCES_V2 feature flag, see PAT-3489
  const hasResourcesV2 = hasFlag(RESOURCES_V2, meStore.features);

  const [currentView, setCurrentView] = useState(defaultView);
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Make sure the correct view is shown when the modal is opened
  useEffect(() => {
    if (open) {
      setCurrentView(defaultView);
    }
  }, [open, defaultView]);

  // Determine modal type based on defaultView
  // These flags are used to determine which buttons to show in the header
  const isDetailModal = defaultView === RESOURCE_MODAL_VIEWS.detail;
  const isShareModal = defaultView === RESOURCE_MODAL_VIEWS.share;

  if (!resource) return null;

  return (
    <Dialog
      fullScreen={isFullScreen}
      fullWidth
      onClose={onClose}
      open={open}
      onTransitionExited={() => setCurrentView(defaultView)}
      PaperProps={{
        sx: [styles.dialogPaper, isShareModal && {pt: 1}],
      }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={[styles.header, isShareModal && styles.noNav]}>
        {isDetailModal && (
          <Box>
            {currentView === RESOURCE_MODAL_VIEWS.detail && (
              <Button
                onClick={() => setCurrentView(RESOURCE_MODAL_VIEWS.share)}
                sx={styles.headerButton}
                startIcon={
                  <Icon
                    name={IconNames.SendDiagonalSolid}
                    size={16}
                    color="primary"
                    style={styles.buttonIcon}
                  />
                }>
                Share
              </Button>
            )}

            {currentView === RESOURCE_MODAL_VIEWS.share && (
              <Button
                onClick={() => setCurrentView(defaultView)}
                sx={styles.headerButton}
                startIcon={
                  <Icon
                    name={IconNames.ArrowLeft}
                    size={16}
                    color="primary"
                    style={styles.buttonIcon}
                  />
                }>
                Back
              </Button>
            )}
          </Box>
        )}

        <IconButton
          onClick={onClose}
          icon={IconNames.Xmark}
          iconProps={{
            name: IconNames.Xmark,
            style: styles.buttonIcon,
          }}
          data-testid="close-dialog"
        />
      </Stack>

      {currentView === RESOURCE_MODAL_VIEWS.detail && !hasResourcesV2 && (
        <AnswerDetailView answer={resource} />
      )}

      {/* Remove RESOURCES_V2 feature flag, see PAT-3489 */}
      {currentView === RESOURCE_MODAL_VIEWS.detail && hasResourcesV2 && (
        <ResourceDetailView resource={resource} onClose={onClose} />
      )}

      {currentView === RESOURCE_MODAL_VIEWS.share && (
        <ShareView resource={resource} onClose={onClose} />
      )}
    </Dialog>
  );
};

const styles = {
  dialogPaper: {
    borderRadius: {
      xs: 0,
      sm: 3,
    },
    maxWidth: '750px',
  },
  header: {
    padding: 2,
    paddingBottom: 0,
  },
  noNav: {
    position: 'absolute',
    right: 8,
    top: 16,
    padding: 0,
  },
  headerButton: {
    textTransform: 'none',
    '& .MuiButton-startIcon': {
      marginRight: 0.75,
      marginLeft: 0,
    },
  },
  buttonIcon: {
    strokeWidth: 2,
  },
};
