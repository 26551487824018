// @flow
import React, {useEffect, useRef} from 'react';
import type {Node} from 'react';
import {Box} from '@wellstone-solutions/web';
import {ResourceGrid} from '../ResourceGrid';
import {useSavedResourcesGridDataloader} from '../../dataloaders';
import {useResourcesContext} from '../../contexts';
import {CollectionIconList} from '../CollectionIconList';


export const SavedResources = (): Node => {
  const initialLoad = useRef(true);
  const {
    savedSearchTerm,
    savedCollectionFilter,
    setSavedCollectionFilter
  } = useResourcesContext();

  const {
    isLoading,
    resources,
    totalCount,
    currentPage,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  } = useSavedResourcesGridDataloader();

  useEffect(() => {
    if (initialLoad.current === false) {
      onFilterChange({
        query: savedSearchTerm,
        collections: savedCollectionFilter,
      });
    } else {
      initialLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSearchTerm, savedCollectionFilter]);

  return (
    <Box>

      <Box mb={3}>
        <CollectionIconList
          setSelectedCollections={setSavedCollectionFilter}
          selectedCollections={savedCollectionFilter}
        />
      </Box>

      <ResourceGrid
        isLoading={isLoading}
        resources={resources}
        totalCount={totalCount}
        currentPage={currentPage}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </Box>
  );
};
