// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {
  Box,
  Stack,
  Grid,
  Progress,
  ProgressTypes,
  TablePagination,
} from '@wellstone-solutions/web';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {ResourceCard} from '../ResourceCard';
import {ResourceModal} from '../ResourceModal';
import {ResourceCount} from '../ResourceCount';
import {RESOURCE_MODAL_VIEWS} from '../../constants';

type PropsType = {
  isLoading: boolean,
  resources: Array<UIResourceType>,
  totalCount: number,
  currentPage: number,
  pageSize: number,
  onPageChange: (page: number) => void,
  onPageSizeChange: (pageSize: number) => void,
};

export const ResourceGrid = ({
  isLoading,
  resources,
  totalCount,
  currentPage,
  pageSize,
  onPageChange,
  onPageSizeChange,
}: PropsType): Node => {
  const [showModal, setShowModal] = useState(false);
  const [modalView, setModalView] = useState(RESOURCE_MODAL_VIEWS.detail);
  const [currentResource, setCurrentResource] = useState(null);

  const openModal = (resource: UIResourceType, view: string) => {
    setCurrentResource(resource);
    setModalView(view);
    setShowModal(true);
  };

  if (isLoading) {
    return (
      <Box sx={[styles.container, styles.flexCenter]}>
        <Progress
          size={20}
          color="primary"
          progressType={ProgressTypes.circular}
        />
      </Box>
    );
  }

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={styles.container}>
        <ResourceCount
          currentCount={resources.length}
          totalCount={totalCount}
        />

        <Grid container spacing={4}>
          {resources.map((resource) => (
            <Grid item key={resource.id} xs={12} sm={6} lg={4} xl={3}>
              <Box
                data-testid="show-detail-modal"
                sx={styles.cardWrapper}
                onClick={() => {
                  openModal(resource, RESOURCE_MODAL_VIEWS.detail);
                }}>
                <ResourceCard
                  resource={resource}
                  onShare={() => {
                    openModal(resource, RESOURCE_MODAL_VIEWS.share);
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        <Box sx={styles.pagination}>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage}
            rowsPerPage={pageSize}
            onPageChange={(event, page) => onPageChange(page)}
            onRowsPerPageChange={(event) =>
              onPageSizeChange(event.target.value)
            }
            labelRowsPerPage="Results per page:"
          />
        </Box>
      </Stack>

      <ResourceModal
        open={showModal}
        onClose={() => setShowModal(false)}
        resource={currentResource}
        defaultView={modalView}
      />
    </>
  );
};

const styles = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    flexGrow: 1,
    minWidth: 0,
  },
  pagination: {
    justifyContent: 'flex-end',
    display: 'flex',
    marginTop: 2,
    '& .MuiTablePagination-root': {borderBottom: 0},
  },
  cardWrapper: {
    ':hover': {
      cursor: 'pointer',
    },
  },
};
