// @flow
import {useState, useEffect, useRef} from 'react';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {useStores} from 'hooks/useStores';
import {useResourcesContext} from '../../v2/contexts';

type ReturnType = {
  isLoading: boolean,
  resources: Array<UIResourceType>,
  totalCount: number,
  currentPage: number,
  pageSize: number,
  onPageChange: (page: number) => void,
  onPageSizeChange: (pageSize: number) => void,
  onFilterChange: ({query: string, collections: Array<string>}) => void,
};

export const useSavedResourcesGridDataloader = (): ReturnType => {
  const initialLoad = useRef(true);
  const {resourceStoreV2} = useStores();
  const {savedSearchTerm, collectionFilter} = useResourcesContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(true);
  const [resources, setResources] = useState<UIResourceType[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const fetchSavedResources = async (
    query: string = '',
    collections: Array<string> = [],
  ) => {
    setIsLoading(true);
    await resourceStoreV2.fetchSavedResources();

    const filteredResources = resourceStoreV2.savedResources.filter(
      (resource) =>
        resource.title.toLowerCase().includes(query.toLowerCase()) &&
        (collections.length === 0 ||
          collections.some((collection) =>
            resource.collections.some((col) => col.id === collection),
          )),
    );

    setTotalCount(filteredResources.length);

    setResources(
      filteredResources.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize,
      ),
    );

    setIsLoading(false);
  };

  const onPageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setCurrentPage(0);
    setPageSize(newPageSize);
  };

  const onFilterChange = ({query, collections}) => {
    setCurrentPage(0);
    fetchSavedResources(query, collections);
  };

  useEffect(() => {
    if (initialLoad.current === false) {
      fetchSavedResources(savedSearchTerm, collectionFilter);
    } else {
      initialLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, savedSearchTerm, collectionFilter]);

  useEffect(() => {
    fetchSavedResources(savedSearchTerm, collectionFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    resources,
    totalCount,
    currentPage,
    pageSize,
    onPageChange,
    onPageSizeChange,
    onFilterChange,
  };
};
