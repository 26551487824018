// @flow
import {useState, useEffect, useRef} from 'react';
import {Hooks, Api} from '@wellstone-solutions/common';
import {Resource} from '@wellstone-solutions/common/models/rest';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {useResourcesContext} from '../contexts';

const {usePaginatedData} = Hooks;

type ReturnType = {
  isLoading: boolean,
  resources: Array<UIResourceType>,
  totalCount: number,
  currentPage: number,
  pageSize: number,
  refetchData: () => void,
  onPageChange: (page: number) => void,
  onPageSizeChange: (pageSize: number) => void,
  onFilterChange: () => void,
};

export const useResourceGridDataloader = (): ReturnType => {
  const initialLoad = useRef(true);
  const {searchTerm, collectionFilter} = useResourcesContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const getActiveFilters = () => {
    const activeFilters = {};
    if (searchTerm) {
      activeFilters.query = searchTerm;
    }

    if (collectionFilter?.length > 0) {
      activeFilters.collections = collectionFilter;
    }

    activeFilters.distance = 25;

    return activeFilters;
  };

  const apiCurrentPage = currentPage + 1;

  const {data, refetch, isLoading} = usePaginatedData({
    url: Resource.routes.search(),
    apiVersion: Api.API_VERSIONS.REST,
    isLimitOffset: false,
    dataTransformer: ({data, total_count}) => ({
      resources: data.map(Resource.toUI),
      totalCount: total_count,
    }),
    currentPage: apiCurrentPage,
    pageSize,
    params: getActiveFilters(),
    sortField: 'title',
    sortOrder: 'asc',
  });

  const refetchData = () => {
    if (refetch) {
      refetch({
        currentPage: apiCurrentPage,
        pageSize,
        params: getActiveFilters(),
      });
    }
  };

  const onPageChange = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setCurrentPage(0);
    setPageSize(newPageSize);
  };

  const onFilterChange = () => {
    // Resetting currentPage will trigger a refetch
    if (currentPage > 0) {
      setCurrentPage(0);
    } else {
      refetchData();
    }
  };

  useEffect(() => {
    if (initialLoad.current === false) {
      refetchData();
    } else {
      initialLoad.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]); // Ensure all dependencies are correct

  return {
    isLoading,
    resources: data?.resources || [],
    totalCount: data?.totalCount || 0,
    currentPage,
    pageSize,
    refetchData,
    onFilterChange,
    onPageChange,
    onPageSizeChange,
  };
};
