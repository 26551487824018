// @flow
import {decorate, observable, action, runInAction} from 'mobx';
import RootStore from 'mobx/RootStore';
import {
  UserResource,
  Collection,
} from '@wellstone-solutions/common/models/rest';
import type {ApiResponseType} from '@wellstone-solutions/common';
import type {
  UICollectionType,
  UIResourceType,
} from '@wellstone-solutions/common/models/rest';
import type {IObservableArray} from 'mobx';

export class ResourceStoreV2 {
  rootStore: RootStore;
  collections: IObservableArray<UICollectionType> = observable.array();
  savedResources: IObservableArray<UIResourceType> = observable.array();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async getCollections(
    forceFetch: boolean = false,
  ): Promise<ApiResponseType<IObservableArray<UICollectionType> | []>> {
    if (!forceFetch && this.collections.length) {
      return {isSuccess: true, errors: [], status: 200, data: this.collections};
    }

    const response = await Collection.getAll();
    if (response.isSuccess) {
      const resourceCollections = response.data.filter(
        (collection) => collection.collection_type.name === 'Resources',
      );

      const uiCollections = resourceCollections.map(Collection.toUI);
      runInAction(() => {
        this.collections.replace(uiCollections);
      });

      return {...response, data: this.collections};
    } else {
      return {...response, data: []};
    }
  }

  async fetchSavedResources(): Promise<ApiResponseType<UIResourceType[]>> {
    const response = await UserResource.getAll();
    if (response.isSuccess) {
      const uiUserResources = response.data.map(UserResource.toUI);
      const resources = uiUserResources.map(
        (savedResource) => savedResource.resource,
      );
      runInAction(() => {
        this.savedResources.replace(resources);
      });
      return {...response, data: resources};
    } else {
      runInAction(() => {
        this.savedResources.replace([]);
      });
      return {...response, data: []};
    }
  }
}

decorate(ResourceStoreV2, {
  collections: observable,
  savedResources: observable,
  getCollections: action,
  fetchSavedResources: action,
});
