// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  Icon,
  IconButton,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ResponsiveDialog} from './ResponsiveDialog';
import {PageTitle} from '../ResourcesHeader';
import {LocationSearch} from 'modules/location';

export const CurrentLocation: ComponentType<{}> = observer((): Node => {
  const {locationStore} = useStores();
  const [dialogAnchorEl, setDialogAnchorEl] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(
    locationStore.currentLocation && locationStore.currentLocation.location,
  );

  let buttonText;
  if (currentLocation && currentLocation.city && currentLocation.state) {
    buttonText = `${currentLocation.city}, ${currentLocation.state}`;
  } else if (currentLocation && currentLocation.formattedAddress) {
    buttonText = currentLocation.formattedAddress;
  } else {
    buttonText = 'Set Location';
  }

  return (
    <>
      <Button
        sx={styles.button}
        variant="text"
        onClick={(event) => setDialogAnchorEl(event.currentTarget.parentNode)}
        startIcon={
          <Icon name={IconNames.MapPin} color={Theme.colorPalette.primary} />
        }>
        {buttonText}
      </Button>

      <ResponsiveDialog
        anchorEl={dialogAnchorEl}
        onClose={() => setDialogAnchorEl(null)}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <PageTitle />
            <Icon name={IconNames.MapPin} color={Theme.colorPalette.primary2} />
          </Stack>

          <IconButton
            icon={IconNames.Xmark}
            onClick={() => setDialogAnchorEl(null)}
            aria-label="Close"
          />
        </Stack>

        <Box mt={1} mb={2.5}>
          <Typography>
            Enter a location to find resources in the area.
          </Typography>
        </Box>

        <LocationSearch
          value={currentLocation}
          onOptionSelect={setCurrentLocation}
        />

        <Button
          disabled={!currentLocation}
          sx={{
            ...styles.button,
            ...styles.updateButton,
          }}
          variant="contained"
          onClick={() => {
            currentLocation &&
              locationStore.saveCurrentLocation(currentLocation);
            setDialogAnchorEl(null);
          }}>
          Update
        </Button>
      </ResponsiveDialog>
    </>
  );
});

const styles = {
  button: {
    textTransform: 'none',
  },
  updateButton: {
    marginTop: 2,
    maxWidth: {
      sm: '100px',
    },
  },
};
