// @flow
import {IconNames} from '@wellstone-solutions/web';
import type {UIResourceType} from '@wellstone-solutions/common/models/rest';
import {getDirectionsUrl} from 'utils/addressHelpers';

type ContactMethodType = {
  text: string,
  iconName: string,
  href: string,
};

export const useContactMethods = (
  resource: UIResourceType,
): {
  contactMethods: Array<ContactMethodType>,
} => {
  const contactMethods: Array<ContactMethodType> = [];

  if (resource?.location?.formattedAddress) {
    contactMethods.push({
      text: resource.location.formattedAddress,
      iconName: IconNames.MapPin,
      href: getDirectionsUrl(resource.location.formattedAddress),
    });
  }

  if (resource?.website) {
    contactMethods.push({
      text: resource.website,
      iconName: IconNames.Laptop,
      href: resource.website,
    });
  }

  if (resource?.phoneNumber) {
    contactMethods.push({
      text: resource.phoneNumber,
      iconName: IconNames.Phone,
      href: `tel:${resource.phoneNumber}`,
    });
  }

  if (resource?.email) {
    contactMethods.push({
      text: resource.email,
      iconName: IconNames.Mail,
      href: `mailto:${resource.email}`,
    });
  }

  return {contactMethods};
};
