// @flow
import React from 'react';
import {observer} from 'mobx-react';
import type {ComponentType, Node} from 'react';

// FROM MUI DOCS
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

import {Theme} from '@wellstone-solutions/common';
import PfBridgeLogo from '../../../../assets/images/PathfinderBridgeLight.svg';

// AppBar in common cannot hold a ref so I am using from MUI
import AppBar from '@mui/material/AppBar';
import {Toolbar, IconButton, IconNames, Box} from '@wellstone-solutions/web';

import {ProfilePanel} from './ProfilePanel';
import {NotificationsPanel} from './NotificationsPanel';
import {SearchPanel} from './SearchPanel.js';

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ScrollProps {
  children: Node;
}
function HideOnScroll(props: ScrollProps) {
  const {children} = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type PropsType = {};
export const SiteHeader: ComponentType<PropsType> = observer((props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          data-testid="responsive-header"
          style={styles.header}
          id="site_header">
          <Toolbar
            data-testid="header_toolbar"
            sx={isMobile ? styles.toolbar_small : styles.toolbar}>
            <IconButton
              icon={IconNames.Menu}
              iconProps={{
                color: Theme.colorPalette.onPrimary,
                size: 35,
                style: {strokeWidth: 2},
                name: 'MENU',
              }}
              color="secondary"
              size="40"
              position="relative"></IconButton>
            <img
              alt="Pathfinder.Bridge"
              src={PfBridgeLogo}
              data-testid="PFLogo"
              style={isMobile ? styles.bridge_logo_small : styles.bridge_logo}
            />
            <Box
              sx={isMobile ? styles.header_tools_mobile : styles.header_tools}>
              <Box
                sx={{
                  ...styles.tool,
                  justifyContent: 'end',
                }}>
                <SearchPanel />
              </Box>
              <Box sx={styles.tool}>
                <NotificationsPanel />
              </Box>
              <Box sx={styles.tool}>
                <ProfilePanel />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
});

const styles = {
  header: {
    backgroundColor: Theme.colorPalette.primary,
    color: Theme.colorPalette.onPrimary,
    height: '80px',
    zIndex: 1100,
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: '60px 200px 1fr',
    width: '100%',
  },
  toolbar_small: {
    display: 'grid',
    gridTemplateColumns: '40px 150px 1fr',
    width: '100%',
    pl: '8px',
    pr: '8px',
  },
  header_tools: {
    display: 'grid',
    gridTemplateColumns: '1fr 65px 65px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
  },
  header_tools_mobile: {
    display: 'grid',
    gridTemplateColumns: '1fr 50px 50px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80px',
  },
  tool: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bridge_logo: {
    width: '170px',
    marginLeft: '0px',
  },
  bridge_logo_small: {
    width: '115px',
    marginLeft: '10px',
  },
};
