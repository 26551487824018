// @flow
import React, {useState} from 'react';
import {observer} from 'mobx-react';
import type {ComponentType} from 'react';

import {Button, IconNames, Icon} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

import {useStores} from 'hooks/useStores';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {SiteHeaderDrawer} from './SiteHeaderDrawer';
import {ActiveCount} from '../../../../components/ActiveCount/ActiveCount';

type PropsType = {};

export const NotificationsPanel: ComponentType<PropsType> = observer(
  (props) => {
    const {meStore} = useStores();
    const theme = useTheme();
    const isAuthenticated = meStore.isAuthenticated();
    const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
      setNotificationAnchorEl(null);
    };
    const handleClick = (event) => {
      let anchor = event.target.parentNode;
      setNotificationAnchorEl(anchor);
    };

    return (
      <>
        {isAuthenticated && (
          <>
            <Button
              onClick={handleClick}
              sx={{padding: '8px 0'}}
              tabIndex={-1}
              data-testid="header-notifications-button">
              <Icon
                name={IconNames.Bell}
                size={isMobile ? 22 : 35}
                color={Theme.colorPalette.onPrimary}
              />
              <ActiveCount
                count={4}
                sx={isMobile ? style.badgeMobile : style.badge}
              />
            </Button>
            <SiteHeaderDrawer
              onClose={handleClose}
              anchorEl={notificationAnchorEl}
              title="Notifications"
              data-testid="header-notifications-panel">
              <div>NotificationList</div>
            </SiteHeaderDrawer>
          </>
        )}
      </>
    );
  },
);

const style = {
  badge: {
    background: Theme.colorPalette.red,
    position: 'absolute',
    right: '3px',
    top: '-3px',
    width: '20px',
    height: '20px',
    textOverflow: 'clip',
    ' .MuiChip-label': {
      textOverflow: 'clip',
      padding: 0,
      fontWeight: 200,
    },
  },
  badgeMobile: {
    background: Theme.colorPalette.red,
    position: 'absolute',
    right: '12px',
    top: '0px',
    width: '20px',
    height: '20px',
    textOverflow: 'clip',
    ' .MuiChip-label': {
      textOverflow: 'clip',
      padding: 0,
      fontWeight: 200,
    },
  },
};
